const getTenancyColumns = () => [
  { Header: "Business Name", accessor: "businessName" },
  { Header: "Customer Identifier", accessor: "customerIdentifier" },
  { Header: "UUID", accessor: "uuid" },
  { Header: "Meraki Org Name", accessor: "merakiOrganizationName" },
  { Header: "State", accessor: "state" },
  {
    Header: "No. Alarmed Devices",
    accessor: "numberOfAlarmedDevices",
  },
  {
    Header: "Date Enrolled",
    accessor: "dateEnrolled",
    minWidth: 30,
    width: 50,
    maxWidth: 200,
    filter: "date",
    Cell: (props) => new Date(props.value).toLocaleString(),
  },
]

export default getTenancyColumns
