import axios from "axios"
import { v4 as uuidv4 } from "uuid"
import envUtil from "../../utilities/env-util"

// Execute list devices workflow
const httpReqListDevices = () =>
  new Promise((resolve, reject) => {
    // Execute the Camunda process
    axios({
      method: "GET",
      baseURL: envUtil.getBaseURL(envUtil.tenants.smbActivationsCamunda),
      url: `/activationAndConfiguration/v4/service/managedDevice`,
      headers: getHeaders(),
      withCredentials: envUtil.getPlatform() === "openshift",
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

const getHeaders = () => {
  const headersForCmi = {
    "Authentication-Data": "Bearer " + localStorage.getItem("cmiPortalToken"),
    "X-Correlation-ID": uuidv4(),
    "X-Group-ID": "MNaaS",
  }
  const headersForPcf = {
    Authorization: "Bearer " + localStorage.getItem("cmiPortalToken"),
    "X-Correlation-ID": uuidv4(),
    "X-Group-ID": "MNaaS",
    "id-token": localStorage.getItem("cmiIdToken"),
  }
  const headers =
    envUtil.getPlatform() === "openshift" ? headersForCmi : headersForPcf
  return headers
}

export default httpReqListDevices
