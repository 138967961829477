import axios from "axios"
import { v4 as uuidv4 } from "uuid"
import envUtil from "../../utilities/env-util"

// Execute the retrieve organisations workflow
const httpReqValidatePartner = (reqBodyValidatePartner) =>
  new Promise((resolve, reject) => {
    // Execute the API Call
    axios({
      method: "POST",
      baseURL: envUtil.getBaseURL(envUtil.tenants.smbActivationsCamunda),
      url: `/validation/partnerCustomer`,
      headers: getHeaders(),
      data: reqBodyValidatePartner,
      withCredentials: envUtil.getPlatform() === "openshift",
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })

const getHeaders = () => {
  const headersForCmi = {
    "Authentication-Data": "Bearer " + localStorage.getItem("cmiPortalToken"),
    "X-Correlation-ID": uuidv4(),
    "X-Group-ID": "MNaaS",
    "Content-Type": "application/json",
  }
  const headersForPcf = {
    Authorization: "Bearer " + localStorage.getItem("cmiPortalToken"),
    "X-Correlation-ID": uuidv4(),
    "X-Group-ID": "MNaaS",
    "Content-Type": "application/json",
  }
  const headers =
    envUtil.getPlatform() === "openshift" ? headersForCmi : headersForPcf
  return headers
}

export default httpReqValidatePartner
