import React from "react"
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
} from "@material-ui/core"
import "./style.scss"

const InputSelectCustom = ({
  inputName,
  inputSelected,
  inputOptions,
  inputReadOnly,
  inputSelectHandler,
  isSearchable,
  placeholder,
  disabled,
  isClearable,
  isLoading,
}) => (
  <FormControl variant="filled" className="input-select-custom">
    <InputLabel id="demo-simple-select-filled-label">{placeholder}</InputLabel>
    {isLoading ? <CircularProgress /> : null}
    <Select
      name={inputName}
      labelId="demo-simple-select-filled-label"
      id="demo-simple-select-outlined"
      value={inputSelected ? inputSelected.value : ""}
      onChange={inputSelectHandler}
      label={placeholder}
      disabled={disabled || isLoading}
    >
      {inputOptions.map((inputOption) => (
        <MenuItem key={inputOption.value} value={inputOption.value}>
          {inputOption.label}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
)

export default InputSelectCustom
