import React, { useState, useEffect } from "react"
import { Button, CircularProgress } from "@material-ui/core"
import isEqual from "lodash.isequal"
import InputNumberCustom from "../../common/input-number-custom"
import InputSelectCustom from "../../common/input-select-custom"
import makeInputOptionsMerakiOrganisations from "../../../services/smb-activations/make-input-options-meraki-organisations.js"
import makeInputSelected from "../../../utilities/make-input-selected"
import InputTextCustom from "../../common/input-text-custom"

const EnrolOrganisationModalChildren = ({
  setModalOpen,
  enrolOrganisationModalInputTextHandler,
  merakiOrganisationSelectHandler,
  enrolOrganisation,
  customerIdentifier,
  businessName,
  merakiOrganisations,
  merakiOrganisationIdSelected,
  updateMerakiOrgSelectedById,
  enrolButtonDisabled,
  cidnInputDisabled,
  retrieveOrganisationLoading,
  validatePartnerLoading,
}) => {
  // Setup state for the meraki orgs select field
  const [inputOptionsMerakiOrganisations, setInputOptionsMerakiOrganisations] =
    useState([])

  // Update the list of Meraki Orgs
  if (merakiOrganisations && merakiOrganisations.length > 0) {
    const inputOptionsMerakiOrganisationsUpdated =
      makeInputOptionsMerakiOrganisations(merakiOrganisations)
    if (
      !isEqual(
        inputOptionsMerakiOrganisations,
        inputOptionsMerakiOrganisationsUpdated
      )
    ) {
      setInputOptionsMerakiOrganisations(inputOptionsMerakiOrganisationsUpdated)
    }
  }

  // Update the input option selected after a new list of Meraki Orgs is loaded
  useEffect(() => {
    if (
      inputOptionsMerakiOrganisations.length > 0 &&
      inputOptionsMerakiOrganisations[0].value !== merakiOrganisationIdSelected
    ) {
      updateMerakiOrgSelectedById(inputOptionsMerakiOrganisations[0].value)
    }
  }, [inputOptionsMerakiOrganisations])

  // Update the meraki org selected
  const inputSelectedMerakiOrganisations = makeInputSelected({
    inputOptions: inputOptionsMerakiOrganisations,
    inputValue: merakiOrganisationIdSelected,
  })

  // Return JSX
  return (
    <div className="ags-record-modal-children">
      <form>
        <InputNumberCustom
          name="customerIdentifier"
          value={customerIdentifier}
          placeholder="Customer Identifier*"
          inputTextHandler={(evt) =>
            enrolOrganisationModalInputTextHandler(evt)
          }
          disabled={cidnInputDisabled}
          isLoading={validatePartnerLoading}
        />
        {businessName != "" && (
          <InputTextCustom
            name="customer"
            value={businessName}
            placeholder="Customer"
            disabled={true}
          />
        )}
        <InputSelectCustom
          inputName="merakiOrganisations"
          inputSelected={inputSelectedMerakiOrganisations}
          inputOptions={inputOptionsMerakiOrganisations}
          inputReadOnly={false}
          inputSelectHandler={merakiOrganisationSelectHandler}
          isSearchable={true}
          placeholder="Meraki Organisation*"
          isClearable={false}
          disabled={false}
          isLoading={retrieveOrganisationLoading}
        />
      </form>
      <Button
        style={{ marginRight: "10px" }}
        variant="contained"
        color="secondary"
        disabled={enrolButtonDisabled && customerIdentifier !== "2948394782"}
        onClick={enrolOrganisation}
      >
        Enrol
      </Button>
      <Button variant="contained" onClick={() => setModalOpen(false)}>
        Cancel
      </Button>
    </div>
  )
}

export default EnrolOrganisationModalChildren
