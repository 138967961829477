/* globals GATSBY_PIMS_MOBILE_CODE */
import React, { useState, useEffect } from "react"
import Helmet from "react-helmet"
import { Typography } from "@material-ui/core"
import { ThemeProvider } from "@material-ui/core/styles"
import TelstraTheme from "../../gatsby-theme-material-ui-top-layout/theme.js"
import Layout from "../../components/layout/layout.js"
import TableCustom from "../../components/common/table-custom/index.js"
import ToastContent from "../../components/common/toast-content.js"
import "react-toastify/dist/ReactToastify.css"
import { toast, ToastContainer } from "react-toastify"
import getTenancyColumns from "../../components/smb-activations/get-tenancy-columns.js"
import ModalCustom from "../../components/common/modal-custom/index.js"
import httpReqRetrieveOrganisations from "../../services/smb-activations/http-req-retrieve-organisations.js"
import httpReqValidatePartner from "../../services/smb-activations/http-req-validate-partner.js"
import EnrolOrganisationModalChildren from "../../components/smb-activations/enrol-organisation-modal-children/index.js"
import httpReqListTenancies from "../../services/smb-activations/http-req-list-tenancies.js"
import httpReqListDevices from "../../services/smb-activations/http-req-list-devices.js"
import envUtil from "../../utilities/env-util"
import makeManagedTenanciesDbToRuntime from "../../services/smb-activations/make-managed-tenancies-db-to-runtime.js"
import extractArrayItemByKey from "../../utilities/extract-array-item-by-key.js"
import httpReqCreateTenancy from "../../services/smb-activations/http-req-create-tenancy.js"
import { getUser } from "../../utilities/userManager"
import { decodeToken } from "react-jwt"
import { useDispatch } from "react-redux"
import {
  enableLoading,
  disableLoading,
} from "../../components/common/state/loadingSlice.js"

// Define the initial state for the enrol organisation modal
const makeEnrolOrganisationModalInitialState = () => ({
  modalOpen: false,
  dealerCode: "",
  customerIdentifier: "",
  merakiOrganisations: [],
  merakiOrganisationIdSelected: null,
})

const makeValidatedPartnerInitialState = () => ({
  businessName: "",
  fixedDealerCode: "",
})

const IndexPage = () => {
  // Use state for tenanciesTableData
  const dispatch = useDispatch()
  const [tenanciesTableData, setTenanciesTableData] = useState([])

  // Refresh tenancies table
  const refreshTenanciesTableData = async () => {
    try {
      // List all managed tenancies from CMDB
      dispatch(enableLoading())
      if (envUtil.getPlatform() === "pcf") getUser()
      const managedTenanciesDb = await httpReqListTenancies()
      const managedDevicesDb = await httpReqListDevices()

      const managedTenancies = makeManagedTenanciesDbToRuntime(
        managedTenanciesDb,
        managedDevicesDb
      )

      // Update the table
      setTenanciesTableData(managedTenancies)
    } catch (error) {
      console.log(error)
      toast.error(<ToastContent>{error.message}</ToastContent>)
    } finally {
      dispatch(disableLoading())
    }
  }

  // Use state for enrolOrganisationModal
  const [enrolOrganisationModal, setEnrolOrganisationModal] = useState(
    makeEnrolOrganisationModalInitialState()
  )

  // Use state for enrolOrganisationModal
  const [validatedPartner, setValidatedPartner] = useState(
    makeValidatedPartnerInitialState()
  )

  // State to disable activate button during enrichment
  const [enrolButtonDisabled, setEnrolButtonDisabled] = useState(true)
  const [validatePartnerLoading, setValidatePartnerLoading] = useState(false)
  const [retrieveOrganisationLoading, setRetrieveOrganisationLoading] =
    useState(false)
  const [cidnInputDisabled, setCidnInputDisabled] = useState(true)

  // Use state for enrolOrganisationModal
  const [merakiOrganisations, setMerakiOrganisations] = useState([])

  // Enrol Organisation modal open handler
  const enrolOrganisationModalOpenHandler = () => {
    setEnrolOrganisationModal({
      ...makeEnrolOrganisationModalInitialState(),
      modalOpen: true,
      merakiOrganisations: merakiOrganisations,
    })
    if (enrolOrganisationModal.merakiOrganisations.length == 0)
      queryOrganisation()
  }

  // Set modal open - create managed tenancy modal
  const setModalOpenEnrolOrganisation = (modalOpen) => {
    setEnrolOrganisationModal({
      ...enrolOrganisationModal,
      modalOpen,
    })
  }

  // Update Meraki Orgs select field
  const queryOrganisation = async () => {
    try {
      setRetrieveOrganisationLoading(true)
      // Define the request body for the retrieve Orgs API
      const httpReqBodyRetrieveOrganisations = {
        type: "Meraki",
        dealerCode:
          localStorage.getItem("cmiIdToken") &&
          decodeToken(localStorage.getItem("cmiIdToken")).pimsfixeddealercode
            ? decodeToken(localStorage.getItem("cmiIdToken"))
                .pimsfixeddealercode
            : GATSBY_PIMS_MOBILE_CODE,
        url: "https://meraki.dashboard.com",
      }

      // Retrieve the orgs
      dispatch(enableLoading())
      const merakiOrganisationResponse = await httpReqRetrieveOrganisations(
        httpReqBodyRetrieveOrganisations
      )
      const merakiOrganisations = merakiOrganisationResponse.organisations

      setMerakiOrganisations(merakiOrganisations)
      setEnrolOrganisationModal({
        ...makeEnrolOrganisationModalInitialState(),
        modalOpen: true,
        merakiOrganisations: merakiOrganisations,
      })
      setCidnInputDisabled(false)
    } catch (error) {
      console.log(error)
      toast.error(<ToastContent>{error.message}</ToastContent>)
    } finally {
      dispatch(disableLoading())
      setRetrieveOrganisationLoading(false)
      checkIfEnrolIsReady()
    }
  }

  // Update the Meraki Org Selected ID
  const updateMerakiOrgSelectedById = (merakiOrganisationIdSelected) => {
    setEnrolOrganisationModal({
      ...enrolOrganisationModal,
      merakiOrganisationIdSelected,
    })
  }

  const checkIfEnrolIsReady = () => {
    setEnrolButtonDisabled(
      validatedPartner.fixedDealerCode == "" ||
        enrolOrganisationModal.merakiOrganisationIdSelected == null
    )
  }

  // Input text handler
  const enrolOrganisationModalInputTextHandler = async (evt) => {
    // Update the text field
    setEnrolOrganisationModal({
      ...enrolOrganisationModal,
      [evt.target.name]: evt.target.value,
    })

    if (
      evt.target.name === "customerIdentifier" &&
      evt.target.value.length === 10
    ) {
      // Save creds before the evt variable is overwritten
      const cidn = evt.target.value

      // Update Meraki Orgs select field
      try {
        setValidatePartnerLoading(true)

        // Define the request body for the retrieve Orgs API
        const httpReqBodyValidatePartner = {
          dealerCode:
            localStorage.getItem("cmiIdToken") &&
            decodeToken(localStorage.getItem("cmiIdToken")).pimsfixeddealercode
              ? decodeToken(localStorage.getItem("cmiIdToken"))
                  .pimsfixeddealercode
              : GATSBY_PIMS_MOBILE_CODE,
          cidn: cidn,
        }

        // Retrieve the orgs
        dispatch(enableLoading())
        const partnerValidationResponse = await httpReqValidatePartner(
          httpReqBodyValidatePartner
        )

        if (partnerValidationResponse.state == `valid`) {
          // Update the validatedPartnerDetails
          setValidatedPartner({
            ...validatedPartner,
            businessName: partnerValidationResponse.businessName,
            fixedDealerCode: partnerValidationResponse.partnerCode,
          })
          setEnrolButtonDisabled(
            enrolOrganisationModal.merakiOrganisationIdSelected == null
          )
        } else {
          toast.error(
            <ToastContent>{`CIDN Invalid. Please check if the numbers are entered correctly`}</ToastContent>
          )
          setValidatedPartner({
            ...makeValidatedPartnerInitialState(),
          })
          setEnrolButtonDisabled(true)
        }
      } catch (error) {
        console.log(error)
        toast.error(<ToastContent>{error.message}</ToastContent>)
      } finally {
        dispatch(disableLoading())
        setValidatePartnerLoading(false)
      }
    }
  }

  // Input select handler
  const merakiOrganisationSelectHandler = async (evt) => {
    setEnrolOrganisationModal({
      ...enrolOrganisationModal,
      merakiOrganisationIdSelected: evt.target.value,
    })
    checkIfEnrolIsReady()
  }

  // Enrol an organisation
  const enrolOrganisation = async () => {
    setEnrolButtonDisabled(true)
    // Return early if one or more mandatory form fields were not provided
    if (
      !enrolOrganisationModal.customerIdentifier ||
      !enrolOrganisationModal.merakiOrganisationIdSelected
    ) {
      toast.error(
        <ToastContent>
          One or more mandatory fields were not provided
        </ToastContent>
      )
      return
    }

    try {
      const merakiOrganisationSelected = extractArrayItemByKey({
        array: enrolOrganisationModal.merakiOrganisations,
        keyName: "id",
        keyValue: enrolOrganisationModal.merakiOrganisationIdSelected,
      })

      // Construct the request body for the POST tenancy request
      const reqBodyCreateTenancy = {
        name: "MNaaS Service",
        description: "MNaaS Discovery",
        state: "active",
        serviceSpecification: {
          id: "231c4203-9dd4-495e-b959-f4f3e775df17",
          href: "serviceCatalogManagement/v4/serviceSpecification/231c4203-9dd4-495e-b959-f4f3e775df17",
          version: "v1.0.0",
          name: "managedTenancy",
        },
        relatedParty: [
          {
            id: enrolOrganisationModal.customerIdentifier,
            role: "Customer",
            name: "SalesforceID",
            "@referredType": "Customer",
          },
          {
            id: "MNaaS",
            role: "InstanceConsumerGroup",
            "@referredType": "InstanceConsumerGroup",
          },
          {
            id: validatedPartner.fixedDealerCode,
            role: "Partner",
            name: "SalesforceID",
            "@referredType": "Partner",
          },
        ],
        serviceCharacteristic: [
          {
            name: "businessName",
            value: validatedPartner.businessName,
          },
          {
            name: "tenancyProfile",
            value: {
              type: "Meraki",
              template: "merakiDiscovery",
              tenancyId: merakiOrganisationSelected.id,
              url: merakiOrganisationSelected.url,
              name: merakiOrganisationSelected.name,
            },
          },
        ],
      }

      // Enrol the new organisation
      dispatch(enableLoading())
      await httpReqCreateTenancy(reqBodyCreateTenancy)

      // Close the modal
      setEnrolOrganisationModal({
        ...enrolOrganisationModal,
        modalOpen: false,
      })

      // Display a success message
      toast.info(
        <ToastContent>Success - the enrolment has commenced</ToastContent>
      )
    } catch (error) {
      console.log(error)
      // Display an error
      toast.error(<ToastContent>{error.message}</ToastContent>)
    } finally {
      dispatch(disableLoading())
    }
  }

  // Component did mount
  useEffect(() => {
    refreshTenanciesTableData()
  }, [])

  return (
    <Layout>
      <ThemeProvider theme={TelstraTheme}>
        <Helmet title="SMB Activations - MNaaS Portal" />
        <ToastContainer
          position="top-center"
          autoClose={3000}
          pauseOnHover={false}
          pauseOnFocusLoss={false}
          className="toast-container"
          toastClassName="toast-info"
          bodyClassName="toast-body"
        />
        <div className="app-wrapper">
          <Typography variant="h2" gutterBottom={true}>
            SMB Activations
          </Typography>
          <TableCustom
            title="Enrolled Organisations"
            columns={getTenancyColumns()}
            data={tenanciesTableData}
            addButtonVisible={true}
            addButtonClickHandler={enrolOrganisationModalOpenHandler}
            addButtonData={null}
            refreshButtonVisible={true}
            refreshButtonClickHandler={refreshTenanciesTableData}
            tableRowClickHandler={null}
            initialState={{
              pageIndex: 0,
              sortBy: [
                {
                  id: "dateEnrolled",
                  desc: true,
                },
              ],
            }}
          />
          <ModalCustom
            modalOpen={enrolOrganisationModal.modalOpen}
            setModalOpen={setModalOpenEnrolOrganisation}
            modalTitle="Enrol Organisation"
          >
            <EnrolOrganisationModalChildren
              setModalOpen={setModalOpenEnrolOrganisation}
              enrolOrganisationModalInputTextHandler={
                enrolOrganisationModalInputTextHandler
              }
              merakiOrganisationSelectHandler={merakiOrganisationSelectHandler}
              enrolOrganisation={enrolOrganisation}
              dealerCode={enrolOrganisationModal.dealerCode}
              customerIdentifier={enrolOrganisationModal.customerIdentifier}
              businessName={validatedPartner.businessName}
              merakiOrganisations={enrolOrganisationModal.merakiOrganisations}
              merakiOrganisationIdSelected={
                enrolOrganisationModal.merakiOrganisationIdSelected
              }
              updateMerakiOrgSelectedById={updateMerakiOrgSelectedById}
              enrolButtonDisabled={enrolButtonDisabled}
              cidnInputDisabled={cidnInputDisabled}
              retrieveOrganisationLoading={retrieveOrganisationLoading}
              validatePartnerLoading={validatePartnerLoading}
            />
          </ModalCustom>
        </div>
      </ThemeProvider>
    </Layout>
  )
}

export default IndexPage
