const extractArrayItemByKey = require("../../utilities/extract-array-item-by-key")

// Convert managed tenancies from DB to runtime
const makeManagedTenanciesDbToRuntime = (managedTenanciesDb, managedDevicesDb) => {
  const managedTenancies = []
  for (let i = 0; i < managedTenanciesDb.length; i++) {
    const customerIdentifierElement = extractArrayItemByKey({
      array: managedTenanciesDb[i].relatedParty,
      keyName: "role",
      keyValue: "Customer",
    })
    managedTenancies.push({
      businessName: extractArrayItemByKey({
        array: managedTenanciesDb[i].serviceCharacteristic,
        keyName: "name",
        keyValue: "businessName",
      }).value,
      customerIdentifier: customerIdentifierElement
        ? customerIdentifierElement.id
        : "...",
      uuid: managedTenanciesDb[i].id,
      description: managedTenanciesDb[i].description,
      state: managedTenanciesDb[i].state,
      tenancyProfileType: extractArrayItemByKey({
        array: managedTenanciesDb[i].serviceCharacteristic,
        keyName: "name",
        keyValue: "tenancyProfile",
      }).value.type.toLowerCase(),
      merakiOrganizationName: extractArrayItemByKey({
        array: managedTenanciesDb[i].serviceCharacteristic,
        keyName: "name",
        keyValue: "tenancyProfile",
      }).value.name,
      dateEnrolled: managedTenanciesDb[i].serviceDate,
      numberOfAlarmedDevices: countUUID(managedDevicesDb, managedTenanciesDb[i].id),
    })
  }
  return managedTenancies
}

const countUUID = (managedDevicesDb, tenancyId) => {
  const uuidSet = managedDevicesDb.find((uuidSet) => {
    return uuidSet.tenancyUUID === tenancyId;
  })
  return (uuidSet != undefined) ? uuidSet.deviceUUIDs.length : "Not Found"
}

module.exports = makeManagedTenanciesDbToRuntime
