// Make the input select options for the Meraki Orgs form field
const makeInputOptionsMerakiOrganisations = (merakiOrganisations) => {
  const inputOptionsMerakiOrganisations = []

  for (const merakiOrganisation of merakiOrganisations) {
    inputOptionsMerakiOrganisations.push({
      label: merakiOrganisation.name,
      value: merakiOrganisation.id,
    })
  }

  return inputOptionsMerakiOrganisations
}

export default makeInputOptionsMerakiOrganisations
