import React from "react"
import { TextField, FormControl, CircularProgress } from "@material-ui/core"
import "./style.scss"

const onlyNumbers = (e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '') };

const InputNumberCustom = ({
  name,
  className,
  value,
  placeholder,
  inputTextHandler,
  disabled,
  isLoading,
}) => (
  <FormControl variant="filled">
    {isLoading ? <CircularProgress /> : null}
    <TextField
      className={
        className ? `${className} input-number-custom` : "input-number-custom"
      }
      name={name}
      variant="filled"
      value={value}
      label={placeholder}
      onChange={inputTextHandler}
      disabled={disabled}
      inputProps={{maxLength: 10}}
      onInput={(e) => onlyNumbers(e) }
    />
  </FormControl>
)

export default InputNumberCustom
